import {ListLayout, PageSEO} from "@blogs/blog-components";
import {FrontMatter, Pagination} from "@blogs/types";

export type BlogHomePageProps = {
  pagination: Pagination
  posts: Array<FrontMatter>
  initialDisplayPosts: Array<FrontMatter>
  metaTitle: string
  metaDescription: string
  title?: string
  searchPlaceholderText?: string
}
export const BlogHomePage = (props: BlogHomePageProps) => {
  const {posts, initialDisplayPosts, pagination, metaDescription, metaTitle, title = "All Posts", searchPlaceholderText} = props

  return (
    <>
      <PageSEO
        title={metaTitle}
        description={metaDescription}
      />

      <ListLayout
        posts={posts}
        initialDisplayPosts={initialDisplayPosts}
        pagination={pagination}
        title={title}
        searchPlaceholderText={searchPlaceholderText}
      />
    </>
  )
}

import {Image, ImageProps} from "./Image";
import {useImageArray} from "../contexts/ImageArrayContext";

export const PostImage = (props: ImageProps) => {
  const {src} = props
  const images = useImageArray()
  const index = typeof src === 'string'
    ? images.findIndex(image => src === image.src)
    : -1

  if (index === 0) {
    return <Image priority {...props} />
  }

  return <Image {...props} />
}

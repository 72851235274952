export const PostSignature = () => (
  <>
    <div className='divide-black pt-6 pb-6 text-gray-700 dark:text-gray-300'>
      <p className='mb-5'>Catch you on the next wave,</p>
      <p>Shane Daugherty</p>
    </div>

    <div className='divide-black pt-6 pb-6 text-gray-700 dark:text-gray-300 text-md'>
      <p><i>Disclaimer: Always remember to be a responsible traveler. Respect the local culture, environment, and
        support
        local businesses.</i></p>
    </div>
  </>
)

export * from './blog/[...slug]'
export * from './blog'
export * from './blog/page/[page]'
export * from './404'
export * from './_app'
export * from './_document'
export * from './_error'
export * from './privacy-policy'
export * from './home'
export * from './about'
export * from './tags/[tag]'
export * from './tags/index'
export * from './destinations'
export * from './destinations/[...destination]'

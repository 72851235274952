import {PageSEO} from '../components/SEO'
import {PropsWithChildren, ReactElement} from "react";
import {PageConfig} from "@blogs/types";

type GenericPageLayoutProps = PropsWithChildren<PageConfig & {
  afterTitle?: ReactElement
}>

export const GenericPageLayout = (props: GenericPageLayoutProps) => {
  const {title, metaTitle, description, metaDescription, children, afterTitle} = props

  return (
    <>
      <PageSEO title={metaTitle ?? title} description={metaDescription ?? description}/>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        <div className="space-y-2 pt-6 pb-8 md:space-y-5">
          <h1
            className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            {title}
          </h1>
          {afterTitle}
        </div>

        <div className="prose max-w-none pt-8 pb-8 dark:prose-dark">{children}</div>
      </div>
    </>
  )
}

export default GenericPageLayout

import React from 'react'
import {PageSEO, useSiteMetadata} from "@blogs/blog-components";

export const PrivacyPolicy = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <PageSEO title='Privacy Policy' description='Privacy Policy' />

      <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-6 sm:py-12">
        <div className="relative py-3 sm:mx-auto sm:max-w-xl">
          <div className="absolute inset-0 -skew-y-6 transform bg-gradient-to-r from-teal-400 to-cyan-500 shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"></div>
          <div className="relative bg-white px-4 py-10 shadow-lg sm:rounded-3xl sm:p-20">
            <h1 className="mb-4 text-2xl font-semibold">Privacy Policy</h1>
            <p className="mb-4">Effective Date: 6/18/2023</p>
            <p className="mb-4">
              Welcome to {siteMetadata.title}. This privacy policy has been compiled to better serve
              those who are concerned with how their ‘Personally identifiable information’ (PII) is
              being used online. PII, as used in US privacy law and information security, is
              information that can be used on its own or with other information to identify, contact,
              or locate a single person, or to identify an individual in context.
            </p>
            <h2 className="mb-2 font-semibold">What personal information do we collect?</h2>
            <p className="mb-4">
              When registering on our site, as appropriate, you may be asked to enter your name, email
              address, or other details to help you with your experience.
            </p>
            <h2 className="mb-2 font-semibold">When do we collect information?</h2>
            <p className="mb-4">
              We collect information from you when you subscribe to a newsletter or enter information
              on our site.
            </p>
            <h2 className="mb-2 font-semibold">How do we protect your information?</h2>
            <p className="mb-4">
              Your personal information is contained behind secured networks and is only accessible by
              a limited number of persons who have special access rights to such systems, and are
              required to keep the information confidential.
            </p>
            <h2 className="mb-2 font-semibold">Do we use cookies?</h2>
            <p className="mb-4">
              Yes. Cookies are small files that a site or its service provider transfers to your
              computer’s hard drive through your Web browser (if you allow) that enables the site’s or
              service provider’s systems to recognize your browser and capture and remember certain
              information.
            </p>
            <h2 className="mb-2 font-semibold">Third-party disclosure</h2>
            <p className="mb-4">
              We do not sell, trade, or otherwise transfer to outside parties your personally
              identifiable information.
            </p>
            <h2 className="mb-2 font-semibold">Contact Us</h2>
            <p className="mb-4">
              If you have any questions or concerns regarding this privacy policy, please contact us
              at shane@shanedaugherty.com.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

import Link from 'next/link'
import {kebabCase} from "@blogs/utils";

type TagProps = {
  text: string
}
export const Tag = ({ text }: TagProps) => {
  return (
    <Link legacyBehavior href={`/tags/${kebabCase(text)}`}>
      <a className="mr-3 text-sm font-medium uppercase text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
        {text.split(' ').join('-')}
      </a>
    </Link>
  )
}

import {useTheme} from "next-themes";
import {SVGProps, useEffect, useState} from "react";

export const HeaderLogo = (props: SVGProps<any>) => {
  const {theme} = useTheme()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  let bgColor = '#000000'

  if (isMounted) {
    bgColor = theme === 'dark' ? "#eeeeee" : '#000000'
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="227.71 359.16 104.83 105.91"
      width={50}
      height={50}
      {...props}
    >
      <linearGradient
        id="a"
        x1={278.102}
        x2={278.102}
        y1={361.89}
        y2={452.662}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#62e5f8",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#0ab7d4",
          }}
        />
      </linearGradient>
      <path
        d="M324.922 388.079s-11.677-24.814-36.989-28.291c-25.312-3.477-45.9 8.125-52.854 20.085-6.954 11.96-9.177 32.683-5.839 42.697s10.424 28.321 20.721 35.742c7.651 5.516 21 8.484 36.994 5.702 15.994-2.781 35.464-15.02 41.166-28.928 5.702-13.907 7.097-28.649-3.199-47.007z"
        style={{
          fill: bgColor,
        }}
      />
      <path
        fill="url(#a)"
        d="M285.332 375.932c-3.614-11.589-6.337-12.069-7.23-13.073-1.146 1.237-3.616 1.484-7.23 13.073-3.518 11.283-4.844 34.77-4.844 34.77 0 .939.022 1.417.022 1.417 0 32.287 7.136 47.355 12.052 47.355s12.052-15.068 12.052-47.355c0 0 .022-.477.022-1.417 0 0-1.326-23.487-4.844-34.77zm-7.23 78.162V412.74l-3.617 2.41 3.617-49.14v44.042l4.079-2.597-4.079 46.639z"
      />
      <path
        d="m278.102 366.011-3.617 49.139 3.617-2.41v41.354l4.079-46.639-4.079 2.596v-44.04Z"
        style={{
          fillRule: "nonzero",
          paintOrder: "stroke",
          strokeWidth: 0,
          strokeOpacity: 0,
        }}
      />
    </svg>
  )
}

/* eslint-disable react/display-name */
import {ComponentType, createElement, useMemo} from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {getMDXComponent} from 'mdx-bundler/client'
import {Image} from './Image'
import {Link} from './Link'
import {TOCInline} from './TOCInline'
import {Pre} from './Pre'
import {BlogNewsletterForm} from './NewsletterForm'
import {Author, FrontMatter} from "@blogs/types";
import {PostImage} from "./PostImage";

type WrapperProps = {
  components: any
  layout: string|ComponentType
}

export const MDXComponents = {
  Image: PostImage,
  TOCInline,
  a: Link,
  pre: Pre,
  BlogNewsletterForm: BlogNewsletterForm,
  wrapper: ({components, layout, ...rest}: WrapperProps) => {
    if (typeof layout === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const Layout = require(`@blogs/blog-components`)[layout]

      return <Layout {...rest} />
    }

    return createElement(layout, rest)
  },
}

export type MDXLayoutRendererProps = {
  layout: string|ComponentType<any>
  mdxSource: any
  toc?: any
  frontMatter: FrontMatter
  authorDetails?: Array<Author>
  next?: FrontMatter
  prev?: FrontMatter
  seoProps?: any
}
export const MDXLayoutRenderer = ({layout, mdxSource, ...rest}: MDXLayoutRendererProps) => {
  const MDXLayout = useMemo(() => getMDXComponent(mdxSource), [mdxSource])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <MDXLayout layout={layout} components={MDXComponents} {...rest} />
}

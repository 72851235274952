import {Link} from './Link'
import {SectionContainer} from './SectionContainer'
import {Footer} from './Footer'
import {MobileNav} from './MobileNav'
import {ThemeSwitch} from './ThemeSwitch'
import {PropsWithChildren, createElement} from "react";
import {HeaderNavLinks} from "@blogs/types";
import {useSiteMetadata} from "../contexts/SiteMetadataContext";

type LayoutWrapperProps = PropsWithChildren<{
  headerNavLinks: HeaderNavLinks
}>

export const LayoutWrapper = ({children, headerNavLinks}: LayoutWrapperProps) => {
  const siteMetadata = useSiteMetadata()

  return (
    <SectionContainer>
      <div className="flex h-screen flex-col justify-between">
        <header className="flex items-center justify-between py-10">
          <div>
            <Link legacyBehavior href="/" aria-label={siteMetadata.headerTitle}>
              <div className="flex items-center justify-between">
                <div className="mr-3">
                  {createElement(siteMetadata.header.logo)}
                </div>
                <div className="hidden text-2xl font-semibold sm:block logo-text">
                  {siteMetadata.headerTitle}
                </div>
              </div>
            </Link>
          </div>
          <div className="flex items-center text-base leading-5">
            <div className="hidden sm:block">
              {headerNavLinks.map((link) => (
                <Link legacyBehavior
                      key={link.title}
                      href={link.href}
                      className="p-1 font-medium text-gray-900 dark:text-gray-100 sm:p-4"
                >
                  {link.title}
                </Link>
              ))}
            </div>
            <ThemeSwitch/>
            <MobileNav headerNavLinks={headerNavLinks}/>
          </div>
        </header>
        <main className="mb-auto">{children}</main>
        <Footer/>
      </div>
    </SectionContainer>
  )
}

/* eslint-disable jsx-a11y/anchor-has-content */
import NextLink from 'next/link'
import {ComponentProps, PropsWithChildren} from "react";

type CustomLinksProps = PropsWithChildren<{
  href?: string
}> & ComponentProps<typeof NextLink>

export const Link = ({ href, legacyBehavior, ...rest }: CustomLinksProps) => {
  const isInternalLink = href && href.startsWith('/')
  const isAnchorLink = href && href.startsWith('#')

  if (isInternalLink) {
    return (
      <NextLink legacyBehavior href={href}>
        <a {...rest} />
      </NextLink>
    )
  }

  if (isAnchorLink) {
    return <a href={href} {...rest} />
  }

  return <a target="_blank" rel="noopener noreferrer" href={href} {...rest} />
}

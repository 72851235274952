import {Destination, FrontMatter} from "@blogs/types";
import {DestinationSEO, ListLayout} from "@blogs/blog-components";
import {ReactElement} from "react";

export type DestinationPageProps = {
  posts: Array<FrontMatter>,
  slug: Array<string>,
  data: Destination
  metaTitle?: (data: Destination) => string
  metaDescription?: (data: Destination) => string
  afterTitle?: ReactElement
}

export const DestinationPage = (props: DestinationPageProps) => {
  const {
    data,
    metaDescription = (destination) => `Explore ${destination.name}`,
    metaTitle = (destination) => `Explore ${destination.name}`,
    posts,
    afterTitle
  } = props

  // Capitalize first letter and convert space to dash
  const title = data.name

  return (
    <>
      <DestinationSEO
        title={metaTitle(data)}
        description={metaDescription(data)}
      />
      <ListLayout
        posts={posts}
        title={title}
        afterTitle={afterTitle}
      />
    </>
  )
}

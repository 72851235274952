import {SiteMetadata} from "@blogs/types";
import template from "lodash/template";

type BuildImageUrlOptions = {
  siteMetadata: SiteMetadata,
  width: string | number
  height?: string | number
  src: string
  dpi?: number
}

export const buildImageUrl = (options: BuildImageUrlOptions) => {
  const {
    siteMetadata,
    width,
    height,
    src,
    dpi = 1
  } = options
  const IMAGE_PROXY_TEMPLATE = siteMetadata.IMAGE_PROXY_TEMPLATE?.replace(/{{/g, '${');

  return template(IMAGE_PROXY_TEMPLATE)({
    height: height ?? 0,
    width,
    dpi,
    source: encodeURIComponent(`${siteMetadata.siteUrl}${src}`)
  })
}

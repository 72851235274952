import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useSiteMetadata } from '../contexts/SiteMetadataContext';
import { Link } from './Link';
import { buildImageUrl } from '@blogs/utils';
import { useElementSize } from 'usehooks-ts';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  excludeCredit?: boolean;
  priority?: boolean;
  width?: number|string;
  height?: number|string;
}

export const Image: React.FC<ImageProps> = (props) => {
  const [ref, size] = useElementSize();
  const siteMetadata = useSiteMetadata();

  const { alt, excludeCredit, priority, src, ...rest } = props;

  if (!src) throw new Error('Missing src!')

  const newSrc = useMemo(() => {
    if (!siteMetadata.IMAGE_PROXY_TEMPLATE || process.env.NODE_ENV !== 'production') return src
    const dpi = typeof window !== 'undefined' ? (window?.devicePixelRatio ?? 1) : 1;
    return buildImageUrl({
      siteMetadata,
      src: src ?? '',
      width: size.width ?? props.width,
      height: size.height ?? props.height,
      dpi,
    });
  }, [siteMetadata, src, size.width, size.height, props.width, props.height])

  let newAlt = props.alt;
  let instagramHandle;

  if (alt?.includes('##IG:')) {
    instagramHandle = alt.split('##IG:');
    newAlt = (instagramHandle.shift() ?? alt).trim();
    instagramHandle = instagramHandle[0];
  }

  const loading = priority ? 'eager' : 'lazy';

  if (!excludeCredit && instagramHandle) {
    return (
      <div>
        <img ref={ref}  alt={newAlt} loading={loading} {...rest} src={newSrc} />
        <span>Photo by <Link href={`https://instagram.com/${instagramHandle}`}>@{instagramHandle}</Link></span>
      </div>
    );
  }

  return <img ref={ref}  alt={newAlt} loading={loading} {...rest} src={newSrc} />;
};

import {MDXLayoutRenderer, PageTitle} from "@blogs/blog-components";
import type {PostLayoutProps} from '@blogs/blog-components'
import {FrontMatter} from "@blogs/types";
import type {PostData} from "@blogs/deploy";
import {ComponentType} from "react";

const DEFAULT_LAYOUT = 'PostLayout'

export type BlogSlugPageProps <FM extends FrontMatter = FrontMatter, > = PostLayoutProps & {
  post: PostData<FM>,
  prev?: FM
  next?: FM
  layout?: ComponentType<PostLayoutProps<FM>>
}

export const BlogSlugPage = <FM extends FrontMatter = FrontMatter, > (props: BlogSlugPageProps<FM>) => {
  const {post, authorDetails, prev, next, seoProps, layout, ...customData} = props
  const {mdxSource, toc, frontMatter} = post

  return (
    <>
      {frontMatter.draft !== true ? (
        <MDXLayoutRenderer
          layout={layout || frontMatter.layout || DEFAULT_LAYOUT}
          toc={toc}
          mdxSource={mdxSource}
          authorDetails={authorDetails}
          prev={prev}
          next={next}
          seoProps={seoProps}
          {...customData}
          frontMatter={frontMatter}
        />
      ) : (
        <div className="mt-24 text-center">
          <PageTitle>
            Under Construction{' '}
            <span role="img" aria-label="roadwork sign">
              🚧
            </span>
          </PageTitle>
        </div>
      )}
    </>
  )
}

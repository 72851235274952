import {PageSEO, ListLayout} from "@blogs/blog-components";
import {FrontMatter, Pagination} from "@blogs/types";

export type BlogPostPaginationPageProps = {
  metaTitle: (pagination: Pagination) => string
  metaDescription: (pagination: Pagination) => string
  posts: Array<FrontMatter>
  initialDisplayPosts: Array<FrontMatter>
  pagination: Pagination
  title?: (pagination: Pagination) => string
}

export const BlogPostPaginationPage = (props: BlogPostPaginationPageProps) => {
  const {posts, initialDisplayPosts, pagination, metaTitle, metaDescription, title = (pagination) => `All Posts`} = props

  return (
    <>
      <PageSEO title={metaTitle(pagination)} description={metaDescription(pagination)}/>

      <ListLayout
        posts={posts}
        initialDisplayPosts={initialDisplayPosts}
        pagination={pagination}
        title={title(pagination)}
      />
    </>
  )
}

import Script from 'next/script'
import {useSiteMetadata} from "@blogs/blog-components";
import {useCallback, useEffect} from "react";
import {useRouter} from "next/router";

const GAScript = () => {
  const siteMetadata = useSiteMetadata()
  const router = useRouter()

  const logPageView = useCallback((url: string) => {
    // @ts-ignore
    window?.gtag?.("config", siteMetadata.analytics.googleAnalyticsId, {
      page_path: url,
    })
  }, [siteMetadata.analytics.googleAnalyticsId])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      logPageView(url)
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  if (!siteMetadata.analytics.googleAnalyticsId) return null

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${siteMetadata.analytics.googleAnalyticsId}`}
      />

      <Script strategy="lazyOnload" id="ga-script">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${siteMetadata.analytics.googleAnalyticsId}', {
              page_path: window.location.pathname,
            });
        `}
      </Script>
    </>
  )
}

export default GAScript

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const logEvent = (action: string, category: string, label: string, value: string) => {

  // @ts-ignore
  window?.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

import Document, { Html, Head, Main, NextScript } from 'next/document'
export class CustomDocument extends Document {
  render() {
    return (
      <Html lang="en" className="scroll-smooth">
        <Head />
        <body className="bg-white text-black antialiased dark:bg-gray-900 dark:text-white">
        <Main />
        <NextScript />
        </body>
      </Html>
    )
  }
}


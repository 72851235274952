import {Link} from '../components/Link'
import {Tag} from '../components/Tag'
import {ReactElement, useState} from 'react'
import {Pagination as PaginationComponent} from '../components/Pagination'
import {FrontMatter, Pagination} from "@blogs/types";
import {useSiteMetadata} from "../contexts/SiteMetadataContext";
import {formatDate} from "@blogs/utils";

type ListLayoutProps = {
  title: string
  posts: Array<FrontMatter>
  initialDisplayPosts?: Array<FrontMatter>
  pagination?: Pagination
  breadcrumbs?: Array<{ text: string, link?: string }>
  afterTitle?: ReactElement
  searchPlaceholderText?: string
}
export const ListLayout = (props: ListLayoutProps) => {
  const {posts, title, initialDisplayPosts = [], pagination, afterTitle, searchPlaceholderText = 'Search posts'} = props
  const siteMetadata = useSiteMetadata()
  const [searchValue, setSearchValue] = useState('')
  const filteredBlogPosts = posts.filter((frontMatter) => {
    const searchContent = frontMatter.title + frontMatter.summary + frontMatter?.tags?.join(' ') ?? ''
    return searchContent.toLowerCase().includes(searchValue.toLowerCase())
  })

  // If initialDisplayPosts exist, display it if no searchValue is specified
  const displayPosts =
    initialDisplayPosts.length > 0 && !searchValue ? initialDisplayPosts : filteredBlogPosts

  return (
    <>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        <div className="space-y-2 pt-6 pb-8 md:space-y-5">
          <h1
            className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
            {title}
          </h1>
          {afterTitle}
          <div className="relative max-w-lg">
            <input
              aria-label={searchPlaceholderText}
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={searchPlaceholderText}
              className="block w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-900 dark:bg-gray-800 dark:text-gray-100"
            />
            <svg
              className="absolute right-3 top-3 h-5 w-5 text-gray-400 dark:text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
        <ul>

          {!filteredBlogPosts.length && <p className='mt-2'>There are no posts about {title} yet.</p>}

          {displayPosts.map((frontMatter) => {
            const {slug, date, title, summary, tags, type} = frontMatter

            return (
              <li key={slug} className="py-4" data-cy='post-in-list'>
                <article className="space-y-2 xl:grid xl:grid-cols-4 xl:items-baseline xl:space-y-0">
                  <dl>
                    <dt className="sr-only">Published on</dt>
                    <dd className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                      <time dateTime={date}>{formatDate(date, siteMetadata)}</time>
                    </dd>
                  </dl>
                  <div className="space-y-3 xl:col-span-3">
                    <div>
                      <h3 className="text-2xl font-bold leading-8 tracking-tight">
                        <Link legacyBehavior href={`/${type}/${slug}`} className="text-gray-900 dark:text-gray-100"
                              data-cy='post-title-in-list'>
                          {title}
                        </Link>
                      </h3>
                      <div className="flex flex-wrap">
                        {tags?.map((tag) => (
                          <Tag key={tag} text={tag}/>
                        ))}
                      </div>
                    </div>
                    <div className="prose max-w-none text-gray-500 dark:text-gray-400">
                      {summary}
                    </div>
                  </div>
                </article>
              </li>
            )
          })}
        </ul>
      </div>
      {pagination && pagination.totalPages > 1 && !searchValue && (
        <PaginationComponent currentPage={pagination.currentPage} totalPages={pagination.totalPages}/>
      )}
    </>
  )
}

export default ListLayout

import {Link} from '../components/Link'
import {PageTitle} from '../components/PageTitle'
import {SectionContainer} from '../components/SectionContainer'
import {BlogSEO} from '../components/SEO'
import {Image} from '../components/Image'
import {Tag} from '../components/Tag'
import {Comments} from '../components/comments'
import {ScrollTopAndComment} from '../components/ScrollTopAndComment'
import {Author, FrontMatter} from "@blogs/types";
import {ComponentProps, ComponentType, createElement, PropsWithChildren, ReactElement} from "react";
import {useSiteMetadata} from "../contexts/SiteMetadataContext";
import {FrontMatterContext} from "../contexts/FrontMatterContext";
import {ImageArrayContext} from "../contexts/ImageArrayContext";

export type PostLayoutProps <FM extends FrontMatter = FrontMatter, > = PropsWithChildren<{
  frontMatter: FM
  authorDetails: Array<Author>
  next?: FM
  prev?: FM
  seoProps?: ComponentProps<typeof BlogSEO>
  afterContent?: ReactElement
  images?: Array<{ src: string, alt: string }>
  showPublishedDate?: boolean
  googleMapSrc?: string
  Meta?: ComponentType<PostLayoutProps<FM>>
  Footer?: ComponentType<PostLayoutProps<FM>>
  path: Array<string>
  breadcrumbs: Array<{name: string, href: string}>
}>

const DefaultMeta = <FM extends FrontMatter = FrontMatter, > (props: PostLayoutProps<FM>) => {
  const {authorDetails} = props

  return (
    <dl className="pt-6 pb-10 xl:border-b xl:border-gray-200 xl:pt-11 xl:dark:border-gray-700">
      <dt className="sr-only">Authors</dt>
      <dd>
        <ul className="flex justify-center space-x-8 sm:space-x-12 xl:block xl:space-x-0 xl:space-y-8">
          {authorDetails.map((author) => (
            <li className="flex items-center space-x-2" key={author.name}>
              {author.avatar && (
                <Image
                  src={author.avatar}
                  width="38"
                  height="38"
                  alt="avatar"
                  className="h-10 w-10 rounded-full"
                />
              )}
              <dl className="whitespace-nowrap text-sm font-medium leading-5">
                <dt className="sr-only">Name</dt>
                <dd className="text-gray-900 dark:text-gray-100">{author.name}</dd>
                <dt className="sr-only">Twitter</dt>
                <dd>
                  {author.twitter && (
                    <Link legacyBehavior
                          href={author.twitter}
                          className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
                    >
                      {author.twitter.replace('https://twitter.com/', '@')}
                    </Link>
                  )}
                </dd>
              </dl>
            </li>
          ))}
        </ul>
      </dd>
    </dl>
  )
}

const DefaultFooter = <FM extends FrontMatter = FrontMatter, > (props: PostLayoutProps<FM>) => {
  const {frontMatter, next, prev} = props
  const {tags, type} = frontMatter

  return (
    <footer>
      <div
        className="divide-gray-200 text-sm font-medium leading-5 dark:divide-gray-700 xl:col-start-1 xl:row-start-2 xl:divide-y">
        {tags && (
          <div className="py-4 xl:py-8">
            <h2 className="text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400">
              Tags
            </h2>
            <div className="flex flex-wrap">
              {tags?.map((tag) => (
                <Tag key={tag} text={tag}/>
              ))}
            </div>
          </div>
        )}
        {(next || prev) && (
          <div className="flex justify-between py-4 xl:block xl:space-y-8 xl:py-8">
            {prev && (
              <div>
                <h2 className="text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400">
                  Previous Article
                </h2>
                <div className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
                  <Link legacyBehavior href={`/${prev.type}/${prev.slug}`}>{prev.title}</Link>
                </div>
              </div>
            )}
            {next && (
              <div>
                <h2 className="text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400">
                  Next Article
                </h2>
                <div className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
                  <Link legacyBehavior href={`/${next.type}/${next.slug}`}>{next.title}</Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="pt-4 xl:pt-8">
        <Link legacyBehavior
              href={`/${type}`}
              className="text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
        >
          &larr; Back to the {type}
        </Link>
      </div>
    </footer>

  )
}

export const PostLayout = <FM extends FrontMatter = FrontMatter, > (props: PostLayoutProps<FM>) => {
  const {
    frontMatter,
    children,
    seoProps,
    afterContent,
    showPublishedDate = true,
    images = [],
    Meta = DefaultMeta,
    Footer = DefaultFooter
  } = props

  const {slug, date, title, type} = frontMatter
  const siteMetadata = useSiteMetadata()

  return (
    <SectionContainer>
      <BlogSEO
        url={`${siteMetadata.siteUrl}/${type}/${slug}`}
        {...frontMatter}
        {...seoProps}
      />
      <ScrollTopAndComment/>
      <article>
        <div className="xl:divide-y xl:divide-gray-200 xl:dark:divide-gray-700">
          <header className="pt-6 xl:pb-6">
            <div className="space-y-1 text-center">
              {showPublishedDate && (<dl className="space-y-10">
                <div>
                  <dt className="sr-only">Published on</dt>
                  <dd className="text-base font-medium leading-6 text-gray-500 dark:text-gray-400">
                    <time dateTime={date}>
                      {new Date(date).toLocaleDateString(siteMetadata.locale, {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </time>
                  </dd>
                </div>
              </dl>)}
              <div>
                <PageTitle>{title}</PageTitle>
              </div>
            </div>
          </header>
          <div
            className="divide-y divide-gray-200 pb-8 dark:divide-gray-700 xl:grid xl:grid-cols-4 xl:gap-x-6 xl:divide-y-0"
            style={{gridTemplateRows: 'auto 1fr'}}
          >
            {createElement(Meta, props)}

            <div className="divide-y divide-gray-200 dark:divide-gray-700 xl:col-span-3 xl:row-span-2 xl:pb-0">
              <FrontMatterContext.Provider value={frontMatter}>
                <ImageArrayContext.Provider value={images}>

                  <div className="prose max-w-none pt-10 pb-8 dark:prose-dark">
                    {children}
                    {afterContent}
                  </div>

                  {siteMetadata.postSignature && createElement(siteMetadata.postSignature)}

                  <Comments frontMatter={frontMatter}/>
                </ImageArrayContext.Provider>
              </FrontMatterContext.Provider>
            </div>

            {createElement(Footer, props)}
          </div>
        </div>
      </article>
    </SectionContainer>
  )
}

export default PostLayout

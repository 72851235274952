import {SiteMetadata} from "@blogs/types";
import {HeaderLogo} from "../components/HeaderLogo";
import {PostSignature} from "../components/PostSignature";

export const siteMetadata: SiteMetadata = {
  title: 'Roaming Surfer',
  author: 'Shane Daugherty',
  headerTitle: 'Roaming Surfer',
  description: 'Catching Waves & Collecting Memories.',
  language: 'en-us',
  theme: 'system', // system, dark or light
  siteUrl: 'https://www.roamingsurfer.com',
  siteLogo: '/static/images/logo.png',
  image: '/static/images/avatar.jpeg',
  socialBanner: '/static/images/twitter-card.png',
  email: 'shane@shanedaugherty.com',
  youtube: 'https://www.youtube.com/channel/UCv1dEqUrUblj_5SDuCQTsiQ',
  instagram: 'https://www.instagram.com/roamingsurfer/',
  locale: 'en-US',
  postSignature: PostSignature,
  IMAGE_PROXY_TEMPLATE: `https://images.roamingsurfer.com/extra-secure/rs:fill:$\{width}:$\{height}:0:0/g:ce/sm:1/dpr:\${dpi}/plain/$\{source}`,
  types: [{ name: 'posts' }, { name: 'surf-spots', baseUrl: 'destinations' }],
  header: {
    logo: HeaderLogo
  },
  footer: {
    tagline: 'Surf and Travel Blog'
  },
  analytics: {
    googleAnalyticsId: 'G-M3QE1Z0GMW', // e.g. UA-000000-2 or G-XXXXXXX
  },
  newsletter: {
    provider: 'mailchimp',
  },
  comment: {
    provider: 'disqus',
    disqusConfig: {
      shortname: 'roamingsurfer-com'
    }
  },
}

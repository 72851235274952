import {ListLayout, TagSEO, useSiteMetadata} from "@blogs/blog-components";
import {FrontMatter} from "@blogs/types";

export type TagPageProps = {
  metaTitle: (tag: string) => string
  metaDescription: (tag: string) => string
  posts: Array<FrontMatter>
  tag: string
}

export const TagPage = ({posts, tag, metaDescription, metaTitle}: TagPageProps) => {
  const siteMetadata = useSiteMetadata()

  // Capitalize first letter and convert space to dash
  const title = tag[0].toUpperCase() + tag.split(' ').join('-').slice(1)

  return (
    <>
      <TagSEO
        title={metaTitle(tag)}
        description={metaDescription(tag)}
      />
      <ListLayout posts={posts} title={title}/>
    </>
  )
}

import Mail from './mail.svg'
import Github from './github.svg'
import Facebook from './facebook.svg'
import Youtube from './youtube.svg'
import Linkedin from './linkedin.svg'
import Twitter from './twitter.svg'
import {ComponentType} from "react";

type IconComponent = ComponentType<{className?: string}>

// Icons taken from: https://simpleicons.org/
const components: Record<string, IconComponent> = {
  mail: Mail as unknown as IconComponent,
  github: Github as unknown as IconComponent,
  facebook: Facebook as unknown as IconComponent,
  youtube: Youtube as unknown as IconComponent,
  linkedin: Linkedin as unknown as IconComponent,
  twitter: Twitter as unknown as IconComponent,
}

type SocialIconProps = {
  kind: string
  href: string
  size?: number|string
}

export const SocialIcon = ({ kind, href, size = 8 }: SocialIconProps): JSX.Element | null => {
  if (!href || (kind === 'mail' && !/^mailto:\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(href)))
    return null

  const SocialSvg = components[kind]

  return (
    <a
      className="text-sm text-gray-500 transition hover:text-gray-600"
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      <span className="sr-only">{kind}</span>

      <SocialSvg
        className={`fill-current text-gray-700 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-400 h-${size} w-${size}`}
      />
    </a>
  )
}

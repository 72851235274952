import {Destination} from "@blogs/types";
import classNames from "classnames";
import {GenericPageLayout, Link, LoadingSpinner} from "@blogs/blog-components";
import {ComponentType, createElement, useState} from "react";

export type DestinationChildrenProps = Destination & {
  level: number
  filter?: (destination: Destination, level: number, searchValue: string) => boolean
  searchValue: string
  DestinationChildren: ComponentType<DestinationChildrenProps>
  buildClassNames?: (level: number) => any
}
export const DefaultDestinationChildren = (props: DestinationChildrenProps) => {
  const {
    subDestinations,
    name,
    level,
    path,
    filter,
    searchValue,
    DestinationChildren,
    buildClassNames = (level: number) => classNames({
      'ml-4': level === 1,
      'ml-6': level === 2,
      'ml-8': level === 3,
      'ml-10': level === 4
    })
  } = props

  if (filter?.(props, level, searchValue) === false) {
    return null
  }

  return (
    <div className={buildClassNames(level)}>
      <Link
        href={`/destinations${path}`}
        style={{textDecoration: 'none'}}
        className={classNames("text-sm uppercase text-gray-600 dark:text-gray-300", {
          'font-semibold': level === 1
        })}
      >
        {createElement(`h${level + 2}`, { className: 'text-sm' }, name)}
      </Link>

      {Object.values(subDestinations ?? {}).map((subDestination) => {
        return (
          <DestinationChildren
            searchValue={searchValue}
            filter={filter}
            level={level + 1}
            key={subDestination.id}
            buildClassNames={buildClassNames}
            DestinationChildren={DestinationChildren}
            {...subDestination}
          />
        )
      })}
    </div>
  )
}

export type DestinationsPageProps = {
  destinations: Array<Destination>
  title?: string
  metaTitle?: string
  metaDescription?: string
  filter?: (destination: Destination, level: number, searchValue: string) => boolean
  DestinationChildren?: ComponentType<DestinationChildrenProps>
  isLoading?: boolean
}

export const DestinationsPage = (props: DestinationsPageProps) => {
  const {
    destinations,
    metaDescription = 'Browse destinations.',
    metaTitle = 'Destinations',
    title = 'Destinations',
    filter = () => true,
    DestinationChildren = DefaultDestinationChildren,
    isLoading = false
  } = props

  const [searchValue, setSearchValue] = useState('')
  const sortedTopLevelDestinations = Object.values(destinations)
    .filter((destination) => filter(destination, 0, searchValue))
    .sort((a, b) => a.name > b.name ? -1 : 1)

  return (
    <GenericPageLayout
      title={title}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      afterTitle={
        <div className="relative max-w-lg">
          <input
            aria-label='Search destinations'
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Search destinations'
            value={searchValue}
            className="block w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-900 dark:bg-gray-800 dark:text-gray-100"
          />
          <svg
            className="absolute right-3 top-3 h-5 w-5 text-gray-400 dark:text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      }
    >
      <div className="flex flex-col divide-gray-200">
        {isLoading && (
          <div className='flex w-full item-center justify-center'>
            <LoadingSpinner/>
          </div>
        )}

        {!isLoading && destinations.length === 0 && 'No destinations found.'}

        {!isLoading && sortedTopLevelDestinations.map((destination, index) => {
          return (
            <div key={destination.id} className="mr-5 w-full pl-1">
              <Link
                href={`/destinations${destination.path}`}
                className="text-xl font-semibold uppercase text-gray-600 dark:text-gray-300"
              >
                <h2 className={classNames({
                  'mt-0': index === 0
                })}>
                  {destination.name}
                </h2>
              </Link>

              <div className='flex flex-col'>
                {
                  Object.values(destination?.subDestinations ?? {}).map((sub) => (
                    <DestinationChildren
                      level={1}
                      key={sub.id}
                      filter={props.filter}
                      searchValue={searchValue}
                      DestinationChildren={DestinationChildren}
                      {...sub}
                    />
                  ))
                }
              </div>
            </div>
          )
        })}
      </div>
    </GenericPageLayout>
  )
}

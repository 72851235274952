import dynamic from 'next/dynamic'
import {useSiteMetadata} from "../../contexts/SiteMetadataContext";
import {FrontMatter} from "@blogs/types";

const DisqusComponent = dynamic(
  () => {
    return import('./Disqus')
  },
  { ssr: false }
)
const CommentoComponent = dynamic(
  () => {
    return import('./Commento')
  },
  { ssr: false }
)

export const Comments = ({ frontMatter }: {frontMatter: FrontMatter}) => {
  const siteMetadata = useSiteMetadata()

  const comment = siteMetadata?.comment

  if (!comment || Object.keys(comment).length === 0) return <></>

  return (
    <div id="comment">
      {siteMetadata.comment && siteMetadata.comment.provider === 'disqus' && (
        <DisqusComponent frontMatter={frontMatter} />
      )}
      {siteMetadata.comment && siteMetadata.comment.provider === 'commento' && (
        <CommentoComponent frontMatter={frontMatter} />
      )}
    </div>
  )
}

// components
// import dynamic from "next/dynamic";

export * from './components/analytics'
export {Analytics} from './components/analytics'
export {Comments} from './components/comments'
export {SocialIcon} from './components/social-icons'
export {Card} from './components/Card'
export {ClientReload} from './components/ClientReload'
export {Footer} from './components/Footer'
export {Image} from './components/Image'
export {LayoutWrapper} from './components/LayoutWrapper'
export {Link} from './components/Link'
export {MDXComponents, MDXLayoutRenderer} from './components/MDXComponents'
export type {MDXLayoutRendererProps} from './components/MDXComponents'
export {MobileNav} from './components/MobileNav'
export {NewsletterForm} from './components/NewsletterForm'
export {PageTitle} from './components/PageTitle'
export {Pagination} from './components/Pagination'
export {Pre} from './components/Pre'
export {ScrollTopAndComment} from './components/ScrollTopAndComment'
export {SectionContainer} from './components/SectionContainer'
export {BlogSEO, TagSEO, PageSEO, DestinationSEO} from './components/SEO'
export {Tag} from './components/Tag'
export {ThemeSwitch} from './components/ThemeSwitch'
export {TOCInline} from './components/TOCInline'
export {LoadingSpinner} from './components/LoadingSpinner'

// contexts
export {SiteMetadataContext, useSiteMetadata} from './contexts/SiteMetadataContext'
export {FrontMatterContext, useFrontMatter} from './contexts/FrontMatterContext'

export type {PostLayoutProps} from './layouts/PostLayout'

// export const ListLayout = dynamic(() => import('./layouts/ListLayout'))
// export const AuthorLayout = dynamic(() => import('./layouts/AuthorLayout'))
// export const PostLayout = dynamic(() => import('./layouts/PostLayout'))
// export const PostSimpleLayout = dynamic(() => import('./layouts/PostSimple'))
// export const GenericPageLayout = dynamic(() => import('./layouts/GenericPageLayout'))

export {ListLayout} from './layouts/ListLayout'
export {AuthorLayout} from './layouts/AuthorLayout'
export {PostLayout} from './layouts/PostLayout'
export {PostSimpleLayout} from './layouts/PostSimple'
export {GenericPageLayout} from './layouts/GenericPageLayout'

import {SiteMetadata} from "@blogs/types";

type NewsletterFormProps = {
  title?: string
}

export const NewsletterForm = (props: NewsletterFormProps) => {
  return <div />
}

// export const NewsletterForm = ({ title = 'Subscribe to the newsletter' }: NewsletterFormProps) => {
//   const siteMetadata = useSiteMetadata()
//   const inputEl = useRef<HTMLInputElement>(null)
//   const [error, setError] = useState(false)
//   const [message, setMessage] = useState('')
//   const [subscribed, setSubscribed] = useState(false)
//   const [loading, setLoading] = useState(false)
//
//   const subscribe = async (e: FormEvent) => {
//     setLoading(true)
//     e.preventDefault()
//
//     const res = await fetch(`/api/${siteMetadata.newsletter.provider}`, {
//       body: JSON.stringify({
//         email: inputEl?.current?.value,
//       }),
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       method: 'POST',
//     })
//
//     const { error } = await res.json()
//
//     if (error) {
//       setError(true)
//       setMessage(error ?? 'An error occurred. Please try again later.')
//       Sentry.captureException(error)
//       setLoading(false)
//       return
//     }
//
//     if (inputEl.current) {
//       inputEl.current.value = ''
//     }
//
//     setError(false)
//     setSubscribed(true)
//     setLoading(false)
//     setMessage('Successfully! 🎉 You are now subscribed.')
//   }
//
//   const buttonContent = (() => {
//     if (loading) return '...'
//     return subscribed ? 'Thank you!' : 'Sign up'
//   })()
//
//   const placeholderText = (() => {
//     if (loading) return ''
//     return subscribed ? "You're subscribed !  🎉" : 'Enter your email'
//   })()
//
//   return (
//     <div>
//       <div className={classNames("pb-1 text-lg font-semibold text-gray-800 dark:text-gray-100", {
//         'opacity-50': loading
//       })}>{title}</div>
//       <form className={classNames("flex flex-col sm:flex-row", {
//         'opacity-50': loading
//       })} onSubmit={subscribe}>
//         <div>
//           <label className="sr-only" htmlFor="email-input">
//             Email address
//           </label>
//           <input
//             autoComplete="email"
//             className="w-72 rounded-md px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary-600 dark:bg-black"
//             id="email-input"
//             name="email"
//             placeholder={placeholderText}
//             ref={inputEl}
//             required
//             type="email"
//             disabled={subscribed}
//           />
//         </div>
//         <div className="mt-2 flex w-full rounded-md shadow-sm sm:mt-0 sm:ml-3">
//           <button
//             className={`w-full rounded-md bg-primary-500 py-2 px-4 font-medium text-white sm:py-0 ${
//               (subscribed || loading) ? 'cursor-default' : 'hover:bg-primary-700 dark:hover:bg-primary-400'
//             } focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 dark:ring-offset-black`}
//             type="submit"
//             disabled={subscribed || loading}
//           >
//             {buttonContent}
//           </button>
//         </div>
//       </form>
//       {error && (
//         <div className="w-72 pt-2 text-sm text-red-500 dark:text-red-400 sm:w-96">{message}</div>
//       )}
//     </div>
//   )
// }
//
// export default NewsletterForm

type BlogNewsletterFormProps = {
  title?: string
  siteMetadata: SiteMetadata
}

export const BlogNewsletterForm = ({ title, siteMetadata }: BlogNewsletterFormProps) => (
  <div />
)

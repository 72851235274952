import GA from './GoogleAnalytics'
import {useSiteMetadata} from "../../contexts/SiteMetadataContext";

const isProduction = process.env.NODE_ENV === 'production'

export const Analytics = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      {isProduction && siteMetadata.analytics.googleAnalyticsId && <GA />}
    </>
  )
}

import 'katex/dist/katex.css'
import '@fontsource/inter/variable-full.css'
import Script from 'next/script'

import {ThemeProvider} from 'next-themes'
import {Analytics, LayoutWrapper, SiteMetadataContext} from "@blogs/blog-components";
import Head from "next/head";
import type {HeaderNavLinks, SiteMetadata} from "@blogs/types";
import {ComponentType, createElement} from "react";

type AppPageOptions = {
  head?: ComponentType
  extraComponents?: ComponentType
}

// eslint-disable-next-line react/display-name
export const buildAppPage = (siteMetadata: SiteMetadata, headerNavLinks: HeaderNavLinks, options?: AppPageOptions) => ({Component, pageProps}: any) => {
  return (
    <ThemeProvider attribute="class" defaultTheme={siteMetadata.theme}>

      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/static/favicons/apple-touch-icon.png"/>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          key='favicon'
          href="/static/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicons/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href="/static/favicons/favicon-48x48.png"
        />
        <link rel="manifest" href="/static/favicons/site.webmanifest"/>
        <link rel="mask-icon" href="/static/favicons/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#000000"/>
        <meta name="theme-color" media="(prefers-color-scheme: light)" content="#fff"/>
        <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#000"/>
        <link rel="alternate" type="application/rss+xml" href="/feed.xml"/>
        <Script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9752192284649162"
                crossOrigin="anonymous" />
      </Head>

      {options?.head && createElement(options.head)}

      {/*<Script*/}
      {/*  async*/}
      {/*  id='ga'*/}
      {/*  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9752192284649162"*/}
      {/*  crossOrigin="anonymous">*/}
      {/*</Script>*/}
      <SiteMetadataContext.Provider value={siteMetadata}>
        <Analytics/>
        <LayoutWrapper headerNavLinks={headerNavLinks}>
          <Component {...pageProps} />
          {options?.extraComponents && createElement(options.extraComponents)}
        </LayoutWrapper>
      </SiteMetadataContext.Provider>
    </ThemeProvider>
  )
}

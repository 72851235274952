import {createContext, useContext} from "react";
import {SiteMetadata} from "@blogs/types";

const defaultValue = {} as SiteMetadata

export const SiteMetadataContext = createContext<SiteMetadata>(defaultValue)

export const useSiteMetadata = (): SiteMetadata => {
  const context = useContext(SiteMetadataContext)

  if (context === defaultValue) throw new Error("Missing site metadata context!")

  return context
}

import Head from 'next/head'
import {useRouter} from 'next/router'
import {Author, FrontMatter} from '@blogs/types'
import {useSiteMetadata} from "../contexts/SiteMetadataContext";
import * as Sentry from "@sentry/nextjs";

type CommonSEOProps = {
  title: string
  description: string
  ogType: string
  ogImage: string | Array<{ url: string }>
  twImage: string
  canonicalUrl?: string
}
const CommonSEO = ({title, description, ogType, ogImage, twImage, canonicalUrl}: CommonSEOProps) => {
  const router = useRouter()
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="robots" content="follow, index"/>
        <meta name="description" content={description}/>

        <meta property="og:url" content={`${siteMetadata.siteUrl}${router.asPath}`}/>
        <meta property="og:type" content={ogType}/>
        <meta property="og:site_name" content={siteMetadata.title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:title" content={title}/>

        {Array.isArray(ogImage) ? (
          ogImage.map(({url}) => <meta property="og:image" content={url} key={url}/>)
        ) : (
          <meta property="og:image" content={ogImage} key={ogImage}/>
        )}

        <link
          rel="canonical"
          href={canonicalUrl ? canonicalUrl : `${siteMetadata.siteUrl}${router.asPath}`}
        />
      </Head>

      {siteMetadata.twitter && (
        <Head>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content={siteMetadata.twitter}/>
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content={twImage}/>
        </Head>
      )}
    </>

  )
}

type PageSEOProps = {
  title: string
  description: string
}

export const PageSEO = ({title, description}: PageSEOProps) => {
  const siteMetadata = useSiteMetadata()
  const ogImageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  const twImageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner

  return (
    <CommonSEO
      title={title}
      description={description}
      ogType="website"
      ogImage={ogImageUrl}
      twImage={twImageUrl}
    />
  )
}

type TagSEOProps = {
  title: string
  description: string
}

export const TagSEO = ({title, description}: TagSEOProps) => {
  const siteMetadata = useSiteMetadata()
  const ogImageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  const twImageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  const router = useRouter()
  return (
    <>
      <CommonSEO
        title={title}
        description={description}
        ogType="website"
        ogImage={ogImageUrl}
        twImage={twImageUrl}
      />
      <Head>
        <link
          rel="alternate"
          type="application/rss+xml"
          title={`${description} - RSS feed`}
          href={`${siteMetadata.siteUrl}${router.asPath}/feed.xml`}
        />
      </Head>
    </>
  )
}

export const DestinationSEO = ({title, description}: TagSEOProps) => {
  const siteMetadata = useSiteMetadata()
  const ogImageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  const twImageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  const router = useRouter()
  return (
    <>
      <CommonSEO
        title={title}
        description={description}
        ogType="website"
        ogImage={ogImageUrl}
        twImage={twImageUrl}
      />
      <Head>
        <link
          rel="alternate"
          type="application/rss+xml"
          title={`${description} - RSS feed`}
          href={`${siteMetadata.siteUrl}${router.asPath}/feed.xml`}
        />
      </Head>
    </>
  )
}

type BlogSEOProps = FrontMatter & {
  authorDetails: Array<Author>
  title: string
  summary: string
  date: string
  lastmod: string
  url: string
  images: Array<string> | string
  canonicalUrl?: string
}

export const BlogSEO = ({
                          authorDetails,
                          title,
                          summary,
                          date,
                          lastmod,
                          url,
                          images = [],
                          canonicalUrl,
                          metaDescription,
                          metaTitle
                        }: BlogSEOProps) => {
  const siteMetadata = useSiteMetadata()

  const publishedAt = new Date(date).toISOString()

  let modifiedAt

  try {
    modifiedAt = new Date(lastmod || date).toISOString()
  } catch (ex) {
    Sentry.captureException(ex)
  }

  const imagesArr =
    (images?.length ?? 0) < 1
      ? [siteMetadata.socialBanner]
      : typeof images === 'string'
        ? [images]
        : images

  const featuredImages = imagesArr?.map((img) => {
    return {
      '@type': 'ImageObject',
      url: img.includes('http') ? img : siteMetadata.siteUrl + img,
    }
  })

  let authorList
  if (authorDetails) {
    authorList = authorDetails.map((author) => {
      return {
        '@type': 'Person',
        name: author.name,
      }
    })
  } else {
    authorList = {
      '@type': 'Person',
      name: siteMetadata.author,
    }
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: title,
    image: featuredImages,
    datePublished: publishedAt,
    dateModified: modifiedAt,
    author: authorList,
    publisher: {
      '@type': 'Organization',
      name: siteMetadata.author,
      logo: {
        '@type': 'ImageObject',
        url: `${siteMetadata.siteUrl}${siteMetadata.siteLogo}`,
      },
    },
    description: summary,
  }

  const twImageUrl = featuredImages?.[0]?.url ?? imagesArr[0]

  return (
    <>
      <CommonSEO
        title={metaTitle ?? title}
        description={metaDescription ?? summary}
        ogType="article"
        ogImage={featuredImages}
        twImage={twImageUrl}
        canonicalUrl={canonicalUrl}
      />
      <Head>
        {date && <meta property="article:published_time" content={publishedAt}/>}
        {modifiedAt && <meta property="article:modified_time" content={modifiedAt}/>}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData, null, 2),
          }}
        />
      </Head>
    </>
  )
}

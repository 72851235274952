import {MDXLayoutRenderer} from "@blogs/blog-components";

const DEFAULT_LAYOUT = 'AuthorLayout'

export const AboutPage = ({ authorDetails }: any) => {
  const { mdxSource, frontMatter } = authorDetails

  return (
    <MDXLayoutRenderer
      layout={frontMatter.layout || DEFAULT_LAYOUT}
      mdxSource={mdxSource}
      frontMatter={frontMatter}
    />
  )
}
